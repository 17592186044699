import React, { memo, useEffect } from 'react';
import { Hero, Main } from 'components/home';
import SEO from 'components/seo';

export default memo(()=> {
  useEffect(()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      <SEO title="Aktualności" />
      <Hero />
      <Main />
    </>
  );
});
